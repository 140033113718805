<template>
	<div class="swiper-banner" :class="'swi_'+jsClass">
		<div class="swiper-wrapper">
			<div class="swiper-slide" v-for="item in list">
				<div class="aboutBox aboutBox_phone">
					<div class="widthBox aboutCont">
						<div class="aboutDetail acea-row">
							<div class="aboutL">
								<img id="aboutLeft" :src="item.img" alt="">
							</div>
							<div class="aboutR" id="aboutRight">
								<h4 class="mini_h4">{{ item.entitle }}</h4>
								<h4>{{ item.title }}</h4>
								<p>{{ item.content }}</p>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="swiper-pagination" :class="jsClass"></div>
		<div v-if="navigation" :class="jsClass" class="pic-btn swiper-button-prev acea-row row-center-wrapper">
			<span class="el-icon-arrow-left"></span>
		</div>
		<div v-if="navigation" :class="jsClass" class="pic-btn swiper-button-next acea-row row-center-wrapper">
			<span class="el-icon-arrow-right"></span>
		</div>
		<div class="swiper-scrollbar"></div>
		<div class="acea-row">
			<router-link class="more" style="display: block;" :to="'/about?to=#about_1'">
				クリックしてもっと見る
			</router-link>
		</div>
	</div>
</template>
<script>
import Swiper, {
	Navigation,
	Pagination,
	Autoplay
} from 'swiper';
export default {
	mounted() {
		this.$nextTick(function () {
			this.openSwiper()
		})
	},
	data() {
		return {
			slideIndex: this.bannerIndex ? this.bannerIndex : 0,
		}
	},
	watch: {
		bannerIndex(newVal) {
			if (this.slideIndex == newVal) return
			this.slideIndex == newVal
			var mySwiper = document.querySelector('.swi_' + this.jsClass).swiper
			mySwiper.slideTo(newVal)
		}
	},
	props: {
		bannerIndex: {
			type: Number,
			default: 0
		},
		itemType: {
			type: String
		},
		list: {
			type: Array(),
		},
		jsClass: {
			type: String,
			default: 'swiper'
		},
		navigation: {
			type: Boolean,
			default: true
		}
	},
	computed: {},
	methods: {
		openSwiper(num) {
			var swiper
			var that = this
			swiper = new Swiper('.swi_' + this.jsClass, {
				speed: 300,
				autoplay: {
					delay: 5000
				},
				observer: true,
				initialSlide: this.slideIndex,
				observeParents: true,
				hashNavigation: true,
				modules: [Navigation, Pagination, Autoplay],
				pagination: {
					el: '.swiper-pagination.' + this.jsClass,
					type: 'bullets',
					bulletClass: 'span-look',
					clickable: true,
				},

				navigation: {
					nextEl: '.swiper-button-next.' + this.jsClass,
					prevEl: '.swiper-button-prev.' + this.jsClass,
					hideOnClick: true
				},
				on: {
					slideChangeTransitionEnd(e) {
						that.slideIndex = e.activeIndex
						that.$emit('rotationIndex', e.activeIndex)
					}
				},
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.swiper-banner {
	width: 100%;
	position: relative;
	overflow: hidden;

	img {
		width: 100%;
	}

	.pic-btn {
		position: absolute;
		top: 50%;
		width: 64px;
		height: 64px;
		border-radius: 50%;
		z-index: 2;
		background: #FFF;
		cursor: pointer;

		span {
			font-size: 24px;
			font-weight: bold;
		}

		&.swiper-button-prev {
			left: 50px;
		}

		&.swiper-button-next {
			right: 50px;
		}

		img {
			width: 14px;
		}
	}

	.swiper-pagination {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translate(-50%, 0);
		z-index: 2;

		.span-look {
			width: 16px;
			height: 16px;
			border-radius: 8px;
			background: rgba(25, 35, 80, 0.5) !important;
			display: inline-block;
			margin: 0 4px;

			&.swiper-pagination-bullet-active {
				width: 50px;
				background: rgba(25, 35, 80, 0.8) !important;
			}
		}
	}
}

.aboutBox {
	background-color: rgba($color: #23356E, $alpha: 0.1);

	.aboutCont {
		box-sizing: border-box;
		padding: 80px 100px;
	}

	.aboutDetail {
		position: relative;
		height: auto;

		.about_btn {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			width: 50px;
			height: 65px;
			background: rgba($color: #1D2A55, $alpha: 1.0);
			color: #FFF;
			box-sizing: border-box;
			font-size: 14px;
			line-height: 16px;
			display: flex;
			align-items: center;
			cursor: pointer;

			.btn {
				position: relative;
			}

			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);

				border: {
					top: 10px solid transparent;
					right: 10px solid #FFF;
					bottom: 10px solid transparent;
					left: 10px solid transparent;

				}
			}

			&.prev {
				left: -50px;
				padding-left: 30px;

				&::after {
					left: 0px;
				}
			}

			&.next {
				right: -50px;
				padding-left: 10px;
				padding-right: 20px;

				&::after {
					right: 0px;

					border: {
						right: 10px solid transparent;
						left: 10px solid #FFF;

					}
				}
			}
		}
	}

	.aboutL {
		width: 50%;

		img {
			width: 100%;
		}
	}

	.aboutR {
		width: 50%;
		box-sizing: border-box;
		padding: 140px 46px 40px 50px;
		background: #eee;
		box-shadow: 2px 2px 2px #23356E;

		h4 {
			font-size: 32px;
			color: #1D2A55;
			line-height: 48px;

		}

		p {
			margin-top: 80px;
			font-size: 20px;
			line-height: 40px;
			color: #1D2A55;
			max-height: 300px;
			overflow-y: auto;
		}


	}
}

.more {
	width: 240px;
	height: 56px;
	line-height: 56px;
	font-size: 20px;
	border: 2px solid #1D2A55;
	color: #1D2A55;
	text-align: center;
	border-radius: 10px;
	font-weight: bold;
	cursor: pointer;
}

@media screen and(max-width:768px) {
	.more {
		// margin-top: 10px;
		font-size: 16px;
		width: auto;
		border: 0;
		height: 24px;
		line-height: 24px;
		margin-right: 10px;
		text-align: left;
	}

	.more {
		background: #17204D;
		margin: 0 auto;
		color: #fff;
		border-radius: 0px;
		font-size: 12px;
		padding: 4px 8px;
		margin-top: 40px;
	}

	.swiper-banner {
		.pic-btn {
			display: none !important;
		}

		.swiper-pagination {
			bottom: 10px;
			margin-bottom: 40px;
			::v-deep .span-look {
				width: 12px;
				height: 12px;
				margin: 0 4px;
				background: rgba(25, 35, 80, 0.5);
				&.swiper-pagination-bullet-active {
					width: 12px;
					background: rgba(25, 35, 80, 0.8);
				}
			}
		}
	}

	.about {
		padding-top: 15px;

		.aboutBox {
			margin-top: 20px;

			.aboutCont {
				padding: 20px;
			}

			.aboutR {
				padding: 30px 10px 10px;

				h4 {
					font-size: 16px;
					line-height: 24px;
				}

				p {
					margin-top: 10px;
					font-size: 12px;
					line-height: 20px;
					max-height: 110px;
				}



				.mobileBtn {
					display: inline-block;
					width: 24px;
					height: 24px;
					line-height: 24px;
					font-size: 18px;
					margin: 10px 4px 0;
					text-align: center;
					font-weight: bold;
					color: #333;
					background: rgba(0, 0, 0, 0.20);
				}
			}

			.aboutDetail .about_btn.prev {
				left: -20px;
			}

			.aboutDetail .about_btn.next {
				right: -20px;
			}
		}

		.aboutBox_phone {
			background: none;

			.aboutL {
				width: 100%;
			}

			.aboutR {
				width: 100%;
				background: none;
				box-shadow: none;

				p {
					max-height: 9999px;
					height: auto !important;
					font-size: 13px;
					color: #3E404D;
				}

				h4 {
					text-align: center;
					color: #272E4D;
				}

				.mini_h4 {
					font-size: 10px;
					color: #272E4D;
				}

				text {
					font-size: 10px;
				}


			}
		}
	}
}</style>
