import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/pro',
    name: 'pro',
    component: () => import('../views/pro.vue'),
	children:[
		{
		  path: '/product',
		  name: 'product',
		  component: () => import('../views/product.vue')
		},
		{
		  path: '/pro_detail',
		  name: 'proDetail',
		  component: () => import('../views/pro_detail.vue')
		},
	]
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/case.vue')
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('../views/business.vue')
  },
  {
    path: '/newsCont',
    name: 'newsCont',
    component: () => import('../views/newsCont.vue'),
	children:[
		{
		  path: '/news',
		  name: 'news',
		  component: () => import('../views/news.vue')
		},
		{
		  path: '/news_detail',
		  name: 'newsDetail',
		  component: () => import('../views/news_detail.vue')
		},
	]
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/join.vue')
  },
 {
   path: '/recruit',
   name: 'recruit',
   component: () => import('../views/recruit.vue')
 },
 {
   path: '/team',
   name: 'team',
   component: () => import('../views/team.vue')
 },
 {
   path: '/team_detail',
   name: 'team_detail',
   component: () => import('../views/team_detail.vue')
 },
 {
   path: '/partners',
   name: 'partners',
   component: () => import('../views/partners.vue')
 },
  {path : '/404', component : () => import('@/views/404'), name : '404', meta : {title : '404未找到'}},
  {path : '*', component : () => import('@/views/404'), name : '404', meta : {title : '404未找到'}},
]

const router = new VueRouter({
	mode : 'history',
	scrollBehavior (to, from, savedPosition) {
	      if (to.hash) {
	        return {
	          selector: to.hash
	        }
	      }
	},
	routes
})
export default router
