<template>
	<div class="swiper-banner" :class="'swi_'+jsClass">
		<div class="swiper-wrapper">
			<div class="swiper-slide" v-for="item in picS">
				<img v-if="itemType == 'indpro'" :src="item.main_figure" alt="">
				<img v-else-if="itemType == 'school'" :src="item.back_img" alt="">
				<img v-else-if="itemType == 'join'" :src="item.img" alt="">
				<img v-else :src="item.image" alt="">
			</div>
		</div>
		<div class="swiper-pagination" :class="jsClass"></div>
		<div v-if="navigation" :class="jsClass" class="pic-btn swiper-button-prev acea-row row-center-wrapper">
			<span class="el-icon-arrow-left"></span>
		</div>
		<div v-if="navigation" :class="jsClass" class="pic-btn swiper-button-next acea-row row-center-wrapper">
			<span class="el-icon-arrow-right"></span>
		</div>
		<div class="swiper-scrollbar"></div>
	</div>
</template>
<script>
	import Swiper, {
		Navigation,
		Pagination,
		Autoplay
	} from 'swiper';
	export default {
		mounted() {
			this.$nextTick(function() {
				this.openSwiper()
			})
		},
		data() {
			return {
				slideIndex: this.bannerIndex ? this.bannerIndex : 0,
			}
		},
		watch: {
			bannerIndex(newVal) {
				if(this.slideIndex == newVal) return
				this.slideIndex == newVal
				var mySwiper = document.querySelector('.swi_'+this.jsClass).swiper
				mySwiper.slideTo(newVal)
			}
		},
		props: {
			bannerIndex: {
				type: Number,
				default: 0
			},
			itemType: {
				type: String
			},
			picS: {
				type: Array(),
			},
			jsClass:{
				type:String,
				default:'swiper'
			},
			navigation: {
				type: Boolean,
				default: true
			}
		},
		computed: {},
		methods: {
			openSwiper(num) {
				var swiper
				var that = this
					swiper = new Swiper('.swi_'+this.jsClass, {
						speed:300,
						autoplay : {
						    delay:5000
						},
						observer: true,
						initialSlide: this.slideIndex,
						observeParents: true,
						hashNavigation: true,
						modules: [Navigation, Pagination, Autoplay],
						pagination: {
							el: '.swiper-pagination.'+this.jsClass,
							type: 'bullets',
							bulletClass: 'span-look',
							clickable: true,
						},
						
						navigation: {
							nextEl: '.swiper-button-next.'+this.jsClass,
							prevEl: '.swiper-button-prev.'+this.jsClass,
							hideOnClick: true
						},
						on: {
							slideChangeTransitionEnd(e){
								that.slideIndex = e.activeIndex
								that.$emit('rotationIndex',e.activeIndex)
							}
						},
					})	
			}
		}
	}
</script>

<style lang="scss">
	.swiper-banner {
		width: 100%;
		position: relative;
		overflow: hidden;

		img {
			width: 100%;
		}

		.pic-btn {
			position: absolute;
			top: 50%;
			width: 64px;
			height: 64px;
			border-radius: 50%;
			z-index: 2;
			background: #FFF;
			cursor: pointer;

			span {
				font-size: 24px;
				font-weight: bold;
			}

			&.swiper-button-prev {
				left: 50px;
			}

			&.swiper-button-next {
				right: 50px;
			}

			img {
				width: 14px;
			}
		}

		.swiper-pagination {
			position: absolute;
			bottom: 20px;
			left: 50%;
			transform: translate(-50%, 0);
			z-index: 2;

			.span-look {
				width: 16px;
				height: 16px;
				border-radius: 8px;
				background: rgba($color: #ffffff, $alpha:0.5);
				display: inline-block;
				margin: 0 4px;

				&.swiper-pagination-bullet-active {
					width: 50px;
					background: #FFF;
				}
			}
		}
	}
	@media screen and(max-width:768px) {
		.swiper-banner {
			.pic-btn {
				display: none !important;
			}
		
			.swiper-pagination {
				bottom:10px;
				.span-look {
					width: 12px;
					height: 12px;
					margin: 0 4px;
					&.swiper-pagination-bullet-active {
						width: 12px;
						background: #FFF;
					}
				}
			}
		}
	}
</style>
