<template>
	<div class="home">
    <div class="">
			<div class="" style="padding-top: 0 !important;">
				<div class="">
					<banner jsClass="join2" :picS="indexData.slide_item" :navigation="false"></banner>
				</div>
			</div>
		</div>
		<banner :picS="bannerS" type="video"></banner>
		<div v-if="indexData.company_video" class="video widthBox">
			<video controls :src="indexData.company_video.video"></video>
		</div>
		<div class="widthBox">
			<div class="join">
				<titleOne en='To join in' titleurl="/join" title="ご加盟"></titleOne>
				<div class="picBox">
					<banner itemType="join" jsClass="join" :picS="indexData.join_politely" :navigation="false"></banner>
				</div>
			</div>
			<div class="case">
				<titleOne en='Case sharing' titleurl="/case" id="index_1" title="ケーススタディー"></titleOne>
				<div class="picBox" v-if="mobile">
					<bannerA :mobile="mobile" :slides="1" :scale="false" :autoPlay="false" :padding="50" :list="indexData.case_sharing"></bannerA>
				</div>
				<div class="picBox" v-else>
					<bannerA :autoPlay="false" :padding="50" :list="indexData.case_sharing" :navigation="true"></bannerA>
				</div>
			</div>
		</div>
		<div class="separate">
			<img src="@/assets/pic_04.png" alt="">
		</div>

		<div class="product">
			<titleOne en='PRODUCTS' titleurl="/product" title="商品紹介"></titleOne>
			
			<div class="tabS acea-row row-center hidden-sm-and-up" >
				<div @click="proChange(index,item.id)" v-for="(item,index) in indexData.product_center" class="label" :class="proIndex==index?'active2':''">{{item.name}}</div>
			</div>
			
			<div class="productBox hidden-xs-only">
				<div class="proTitle acea-row row-around-wrapper">
					<div v-for="(item,index) in indexData.product_center" @click="proChange(index,item.id)" :data-index="index" :class="proIndex==index?'active':''" class="item acea-row row-center-wrapper">
						<img src="@/assets/icon_01.png" alt="">
						<div class="txt">
							<p class="name">{{item.name}}</p>
							<p class="en">{{item.enname}}</p>
						</div>
					</div>
				</div>
			</div>
			<template v-if="proIndex == index" v-for="(item,index) in indexData.product_center">
				
				<div class="picBox">
					<img :src="item.back_img" alt="">
				</div>
				<div class="rotation widthBox">
					<div class="tabs" v-if="item.son.length>2">
						<div class="item" v-for="(it,ind) in item.son" :class="proTabIndex == ind?'active':''" @click="proTabChange(ind)" :key="ind"><span>{{it.product}}</span></div>
					</div>
					<banner jsClass="indpro" @rotationIndex="getRotation" itemType="indpro" :bannerIndex="proTabIndex" :picS="item.son" :navigation="false"></banner>
				</div>
				
			</template>
			
		</div> 
		<div class="separate rotateY">
			<img src="@/assets/pic_04.png" alt="">
		</div>
		<div class="about">
			<titleOne id="index_3" titleurl="/about" en='Brand introduction' title="ブランドの紹介"></titleOne>
			<div class="aboutBox hidden-xs-only">
				<div class="widthBox aboutCont">
					<div class="aboutDetail acea-row" v-if="brandIndex==index" v-for="(item,index) in indexData.brand_introduction">
						<div class="aboutL">
							<img id="aboutLeft" :src="item.img" alt="">
						</div>
						<div class="aboutR" id="aboutRight">

							<h4>{{item.title}}<br/>{{item.entitle}}</h4>
							<p>{{item.content}}</p>
							<div class="acea-row">
								<router-link class="more" style="display: block;" :to="'/about?to=#about_1'">
									クリックしてもっと見る
								</router-link>
								<div @click="brandChange" data-type="a" :data-index="index" class="mobileBtn pr el-icon-arrow-left hidden-sm-and-up"></div>
								<div @click="brandChange" data-type="b" :data-index="index" class="mobileBtn nx el-icon-arrow-right hidden-sm-and-up"></div>
							</div>
							
						</div>
						<div @click="brandChange" data-type="a" :data-index="index" class="hidden-xs-only prev about_btn">
							<div class="btn"></div>
						</div>
						<div @click="brandChange" data-type="b" :data-index="index" class="hidden-xs-only next about_btn">
							<div class="btn"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="hidden-sm-and-up">
				<bannerB jsClass="txt" :list="indexData.brand_introduction" :navigation="false"></bannerB>
			</div>
			<!-- <div class="aboutBox aboutBox_phone hidden-sm-and-up">
				<div class="widthBox aboutCont">
					<div class="aboutDetail acea-row" v-for="(item,index) in indexData.brand_introduction">
						<div class="aboutL">
							<img id="aboutLeft" :src="item.img" alt="">
						</div>
						<div class="aboutR" id="aboutRight">
							<h4 class="mini_h4">{{item.entitle}}</h4>
							<h4>{{item.title}}</h4>
							<p>{{item.content}}</p>
							<div class="acea-row">
								<router-link class="more" style="display: block;" :to="'/about?to=#about_1'">
									クリックしてもっと見る
								</router-link>
							</div>
						</div>
						
					</div>
				</div>
			</div> -->
		</div>
		<div class="separate" style="padding-top:0;">
			<img src="@/assets/pic_04.png" alt="">
		</div>
		<div class="news">
			<titleOne id="index_4" en='news information' titleurl="/news" title="最新ニュース"></titleOne>
			<div class="newsBox widthBox acea-row row-between-wrapper">
				<div class="item" v-for="item in indexData.news_information">
					<img :src="item.img" alt="">
					<div class="info">
						<div class="name">{{item.title}}</div>
						<p>{{item.describe}}</p>
						<router-link class="more" :to="'/news_detail?id='+item.id">
							クリックしてもっと見る
						</router-link>
					</div>
				</div>
			</div>
			<router-link class="moreBtn" style="display: block;" :to="'/news'">
				クリックしてもっと見る
			</router-link>
		</div>
	</div>
</template>

<script>
	import banner from '@/components/banner.vue'
	import bannerA from '@/components/bannerA.vue'
	import bannerB from '@/components/bannerB.vue'
	import titleOne from '@/components/titleOne.vue'
	export default {
		name: 'index',
		components: {
			banner,
			bannerA,
			bannerB,
			titleOne,
		},
		computed: {
			bannerS() {
				return this.$store.state.bannerS
			},
		},
		data() {
			return {
				proTab: ['南瓜籽秋葵精华粉', '私密理疗仪', '男士保湿凝胶', '男士润养膏'],
				proTabIndex: 0,
				indexData:{},
				brandIndex:0,
				proIndex:0,
				mobile:false
			}
		},
		created() {
			var width = document.body.clientWidth
			if(width <= 768){
				this.mobile = true
			}
		},
		mounted() {
			this.$api.get('home/index/webIndex',{}).then(res=>{
				this.indexData = res.data
			})
			var to = this.$route.query.to
			if(to){
				this.$el.querySelector(to)
			}
		},
		methods: {
			getRotation(e){
				console.log(e)
				this.proTabIndex = e
			},
			proTabChange(e) {
				this.proTabIndex = e
				console.log(this.proTabIndex)
			},
			proChange(e){
				console.log(e)
				this.proIndex = e
			},
			brandChange(e){
				var type = e.currentTarget.dataset.type
				var brandIndex = this.brandIndex
				if(type == 'a'){
					console.log(1)
					if(brandIndex > 0){
						brandIndex--
					}
				}else if(type == 'b'){
					console.log(2)
					if(brandIndex < (this.indexData.brand_introduction.length-1)){
						brandIndex++
					}
				}
				this.brandIndex=brandIndex
			}
		}
	}
</script>
<style lang="scss" scoped="scoped">
	$paddingTop:60px;
	$marginTop:50px;
	.video{
		height: auto;
		margin: auto;
		margin-top: 70px;
		video{
			width: 100%;
			height: auto;
		}
	}
	.tabS{
		.label{
		background: #fff;
		border: 1px solid #17204D;
		color: #17204D;
		padding: 10px;
		width: 43%;
		text-align: center;
		}
		.active2{
			background: #17204D;
			color: #fff;
		}
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: center;
			padding: 8px;
			margin: 0 auto;
			color: #fff;
			margin-top:40px;
			margin-bottom: 40px;
	}
	.join {
		padding-top: $paddingTop;

		.picBox {
			margin-top: $marginTop;
		}
	}

	.case {
		padding-top: $paddingTop;

		.picBox {
			margin-top: $marginTop;
			overflow: hidden;
		}
	}

	.separate {
		padding-top: $paddingTop;

		&.rotateY {
			transform: rotateY(180deg);
		}

		img {
			width: 100%;
		}
	}

	.product {
		padding-top: $paddingTop;

		.productBox {
			margin-top: $marginTop;

			.proTitle {
				.item {
					cursor: pointer;
					opacity: 0.5;
					margin: 0 0px;
					height: 140px;

					img {
						width: 62px;
						display: none;
					}

					&.active {
						opacity: 1;
						border-bottom: 2px solid #1D2A55;

						img {
							display: block;
						}
					}

					.txt {
						margin-left: 36px;
						color: #1D2A55;
						.name {
							font-size: 36px;
						}

						.en {
							font-size: 18px;
						}
					}
				}
			}
		}

		.picBox {
			img {
				width: 100%;
			}
		}

		.rotation {
			// margin-top: -150px;
			border-radius: 20px;
			overflow: hidden;
			position: relative;

			.tabs {
				background: #1d2a55;
				border-radius:36px;
				background-size: 100% 100%;
				height: 72px;
				position: absolute;
				left: 50%;
				top: 36px;
				padding:0 0px;
				transform: translate(-50%, 0);
				z-index: 2;
				display: flex;

				.item {
					max-width:50%;
					text-align: center;
					color: #FFF;
					height: 72px;
					line-height: 52px;
					cursor: pointer;
					overflow: hidden;
					white-space: nowrap;
					box-sizing: border-box;
					padding:10px 5px;
					text-overflow: ellipsis;
					span{
						font-size: 22px;
						display: block;
						padding:0 6px;
					}
					&.active {
						color: #1D2A55;
						span{
							font-size: 22px;
							background: #FFF;
							border-radius:36px;
							box-shadow: 0px -3px 0 0 rgba($color: #000000, $alpha: 0.1) inset;
						}
					}
				}
			}
		}
	}

	.about {
		padding-top: $paddingTop;

		.aboutBox {
			margin-top: $marginTop;
			background-color: rgba($color: #23356E, $alpha: 0.1);

			.aboutCont {
				box-sizing: border-box;
				padding: 80px 100px;
			}

			.aboutDetail {
				position: relative;
				height: auto;

				.about_btn {
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);
					width: 50px;
					height: 65px;
					background: rgba($color: #1D2A55, $alpha: 1.0);
					color: #FFF;
					box-sizing: border-box;
					font-size: 14px;
					line-height: 16px;
					display: flex;
					align-items: center;
					cursor: pointer;
					.btn {
						position: relative;
					}

					&::after {
						content: '';
						display: block;
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);

						border: {
							top: 10px solid transparent;
							right: 10px solid #FFF;
							bottom: 10px solid transparent;
							left: 10px solid transparent;

						}
					}

					&.prev {
						left: -50px;
						padding-left: 30px;

						&::after {
							left: 0px;
						}
					}

					&.next {
						right: -50px;
						padding-left: 10px;
						padding-right: 20px;

						&::after {
							right: 0px;
							border: {
								right: 10px solid transparent;
								left: 10px solid #FFF;

							}
						}
					}
				}
			}

			.aboutL {
				width: 50%;

				img {
					width: 100%;
				}
			}

			.aboutR {
				width: 50%;
				box-sizing: border-box;
				padding: 140px 46px 40px 50px;
				background: #eee;
				box-shadow: 2px 2px 2px #23356E;

				h4 {
					font-size: 32px;
					color: #1D2A55;
					line-height: 48px;

				}

				p {
					margin-top: 80px;
					font-size: 20px;
					line-height: 40px;
					color: #1D2A55;
					max-height:300px;
					overflow-y: auto;
				}

				.more {
					margin-top: 80px;
					width: 240px;
					height: 56px;
					line-height: 56px;
					font-size: 20px;
					border: 2px solid #1D2A55;
					color: #1D2A55;
					text-align: center;
					border-radius: 10px;
					font-weight: bold;
					cursor: pointer;
				}
			}
		}
	}

	.news {
		padding-top: $paddingTop;

		.newsBox {
			margin-top: $marginTop;

			.item {
				flex: 1;
				background: rgba($color: #1D2A55, $alpha: 0.05);
				margin-left: 20px;

				&:first-child {
					margin-left: 0;
				}

				img {
					width: 100%;
				}

				.info {
					padding: 20px 20px;

					.name {
						font-size: 24px;
						line-height: 36px;
						color: #1D2A55;
						word-break:break-all;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;
					}

					p {
						margin-top: 10px;
						font-size: 18px;
						line-height: 24px;
						color: #1D2A55;
						word-break:break-all;display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;overflow:hidden;
					}

					.more {
						color: #FFF;
						font-size: 12px;
						// width: 125px;
						height: 32px;
						display: inline-block;
						padding: 0 10px;
						line-height: 32px;
						text-align: center;
						background: #1D2A55;
						margin-top: 20px;
					}
				}
			}
		}

		.moreBtn {
			color: #FFF;
			font-size: 20px;
			width: 190px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			background: #1D2A55;
			margin: 40px auto 0 auto;
		}
	}
	
	@media screen and(max-width:768px) {
		.tabS{
			margin:20px 0 ;
			padding: 0px 8px;
		}
		.join{
			padding-top:15px;
			.picBox{
				margin-top:14px;
			}
		}
		
	.video{
		margin-top: 32px;
	}
		.case{
			padding-top:15px;
			.picBox{
				margin-top:14px;
			}
		}
		.separate{
			padding-top:20px;
		}
		.product{
			padding-top:15px;
			.productBox{
				margin-top:14px;
				.proTitle .item{
					margin:0;
					height:auto;
					padding:10px 0;
					img{
						width:21px;
					}
					.txt{
						margin-left:10px;
						.name{
							font-size:16px;
							line-height:32px;
						}
						.en{
							font-size:10px;
						}
					}
				}
			}
			.rotation{
				// margin-top:-40px;
				.tabs{
					display: none;
				}
			}
		}
		.about{
			padding-top:15px;
			.aboutBox{
				margin-top:20px;
				.aboutCont{
					padding:20px;
				}
				.aboutR{
					padding:30px 10px 10px;
					h4{
						font-size:16px;
						line-height:24px;
					}
					p{
						margin-top:10px;
						font-size:12px;
						line-height:20px;
						max-height: 110px;
					}
					.more{
						margin-top:10px;
						font-size:16px;
						width:auto;
						border:0;
						height:24px;
						line-height:24px;
						margin-right:10px;
						text-align: left;
					}
					.mobileBtn{
						display: inline-block;
						width:24px;
						height:24px;
						line-height:24px;
						font-size:18px;
						margin:10px 4px 0;
						text-align: center;
						font-weight: bold;
						color:#333;
						background:rgba(0,0,0,0.20);
					}
				}
				.aboutDetail .about_btn.prev{
					left:-20px;
				}
				.aboutDetail .about_btn.next{
					right:-20px;
				}
			}
			.aboutBox_phone{
				background: none;
				.aboutL{
					width: 100%;
				}
				.aboutR{
					width: 100%;
					background: none;
					box-shadow: none;
					p{
						max-height: 9999px;
						height: auto !important;
						font-size: 13px;
					}
					h4{
						text-align: center;
					}
					.mini_h4{
						font-size: 10px;
					}
					text{
						font-size: 10px;
					}
					.more{
						background: #17204D;
						margin: 0 auto;
						color: #fff;
						border-radius: 0px;
						font-size: 12px;
						padding: 4px 8px;
						margin-top: 40px;
					}
				}
			}
		}
		
		.news{
			padding-top:15px;
			.newsBox{
				margin-top:20px;
				.item{
					flex:inherit;
					margin-left:0;
					margin-bottom:10px;
					.info{
						padding:10px;
						.name{
							font-size:16px;
							line-height:24px;
						}
						p{
							font-size:12px;
							line-height:20px;
						}
						.more{
							// display: none;
							background: none;
							border-bottom: 1px solid #272E4D;
							color: #272E4D;
							padding:   0;
						}
					}
				}
			}
			.moreBtn{
				margin-top:20px;
				font-size:16px;
			}
		}
	}
</style>
