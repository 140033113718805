<template>
  <div  :id="type == 1 ? 'floatBox' : 'floatBox2'">
    <ul>
      <li @click="lyChange">
        <span v-if="type == 1" class="iconfont icon-liuyan acea-row row-center-wrapper"></span>
        <div class="img"><img v-if="type == 2" src="./../assets/msg.png" alt=""></div>
        <p>お問い合わせ</p>
      </li>
      <li @click="kfChange">
        <span v-if="type == 1" class="iconfont icon-kefu acea-row row-center-wrapper"></span>
        <div class="img"><img v-if="type == 2" src="./../assets/headset.png" alt=""></div>
        <p>カスタマーサービス</p>
      </li>
      <li @click="topBtn">
        <span v-if="type == 1" class="iconfont icon-xiangshang acea-row row-center-wrapper" ></span>
        <div class="img"><img v-if="type == 2" src="./../assets/top.png" alt=""></div>
        <p>一番上に戻る</p>
      </li>
    </ul>
    <div>
      <div v-if="kfShow" class="kfBox">
          <div>電話番号</div>
        <div class="kfItem" v-for="item in kfList">
          <p class="tel">{{ item.kf_phone }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    kfShow() {
      return this.$store.state.kfShow;
    },
  },
  props:{
    type:{
      default: 1
    }
  },
  data() {
    return {
      width: 0,
      height: 0,
      kfList: [],
    };
  },
  created() {
    this.$api.get("home/index/kefu", {}).then((res) => {
      this.kfList = res.data.list;
    });
  },
  mounted() {},
  methods: {
    topBtn() {
      document.body.scrollIntoView();
    },
    lyChange() {
      this.$emit("messageShow", true);
    },
    kfChange() {
      this.$store.state.kfShow = true;
      this.$emit("getBgShow", true);
    },
  },
};
</script>

<style lang="scss">
#floatBox {
  position: fixed;
  top: 50%;
  right: 50px;
  z-index: 100;
  transform: translate(0, -50%);
  li {
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
    p {
      font-size: 14px;
      color: #777;
      line-height: 24px;
    }
    .iconfont {
      background: #777;
      width: 60px;
      height: 60px;
      font-size: 32px;
      color: #fff;
      border-radius: 10px;
      margin: 0 auto;
    }
    &:hover {
      .iconfont {
        background: #e11111;
        color: #fff;
        box-shadow: 0px 0px 10px 1px rgba(29, 42, 85, 0.2);
      }
      p{
        color: #e11111;
      }
    }
  }
  .kfBox {
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    width: 240px;
    border-radius: 10px;
    box-sizing: border-box;
    background: #fff;
    text-align: center;
    line-height: 30px;
    padding: 30px 0;
    .pic {
      width: 120px;
    }
		div,
    p {
      font-size: 18px;
      color: #1d2a55;
    }
    .tel {
      font-weight: bold;
      font-size: 20px;
    }
  }
}
#floatBox2{
  width: 100%;
  position: relative;
  ul{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    li{
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-wrap: wrap;
      text-align: center;
    }
  }
  div.img{
    margin: 0 auto;
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    img{
      width: 32px;
      height: 32px;
    }
  }
  .kfBox {
    position: absolute;
    right: calc((100% - 240px) / 2) ;
    top: -70px;
    transform: translateY(-50%);
    width: 240px;
    border-radius: 10px;
    box-sizing: border-box;
    background: #fff;
    text-align: center;
    line-height: 30px;
    padding: 30px 0;
    z-index: 21;
    .pic {
      width: 120px;
    }
		div,
    p {
      font-size: 18px;
      color: #1d2a55;
    }
    .tel {
      font-weight: bold;
      font-size: 20px;
    }
  }
}
</style>
