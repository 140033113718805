<template>
  <div>
    <div class="header_box">
      <div class="header acea-row row-center-wrapper" style="z-index: 10;">
        <div class="widthBox acea-row row-between-wrapper">
          <!-- <img class="logo hidden-sm-and-up" src="@/assets/logo_02.png" alt="">
			<img class="logo hidden-xs-only" src="@/assets/logo_02.png" alt="">
			
			<img @click="mobileShow = !mobileShow" class="icon hidden-sm-and-up" src="@/assets/icon_05.png" alt=""> -->

          <img v-if="!mobileShow" @click="mobileShow = !mobileShow" class="icon hidden-sm-and-up"
            src="@/assets/icon_05.png" alt="" />
          <img v-if="mobileShow" @click="mobileShow = !mobileShow" class="icon hidden-sm-and-up" src="@/assets/close.png"
            alt="" />
          <img class="logo hidden-sm-and-up" src="@/assets/logo_02.png" alt="" />
          <div class="block hidden-sm-and-up">

          </div>
          <img class="logo hidden-xs-only" src="@/assets/logo_02.png" alt="" />
          <div id="nav" class="hidden-xs-only">
            <router-link v-for="(item, index) in navS" :to="item.url" :key="index">{{ item.name }}</router-link>
            <a target="_blank" href="https://phromonjpn.com/">会員専用ページ</a>
          </div>

        </div>
        <div v-if="mobileShow" @click.self="mobileShow = !mobileShow" class="navBg hidden-sm-and-up">
          <div id="navM">
            <div class="nav-item" v-for="item in navS" @click=" mobileShow = false">
              <router-link :to="item.url">{{
                item.name
              }}
                <img src="@/assets/arrow-right.png" alt="">
              </router-link>
            </div>
            <div class="nav-item">
                <a target="_blank" href="https://phromonjpn.com/">会員専用ページ<img src="@/assets/arrow-right.png" alt=""></a>
                
            </div>
          </div>
        </div>

        <floatBox class="hidden-xs-only" @messageShow="messageType" @getBgShow="getShow" />
        <div @click="hideBg" v-if="bgShow" class="fixedBg"></div>
        <div v-if="messageShow" class="messageBox">
          <h4>お問い合わせ</h4>
          <div class="cont">
            <div class="formInput">
              <input v-model="formData.name" placeholder="お名前" type="text" />
              <input v-model="formData.mobile" placeholder="電話番号" type="text" />
              <input v-model="formData.city" placeholder="都道府県名" type="text" />
            </div>
            <textarea v-model="formData.content" rows="5" placeholder="お問い合わせ内容"></textarea>
            <button @click="messageSubmit">送信</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="banner" class="banner">
      <img :src="banner" alt="" />
    </div>
  </div>
</template>

<script>
import floatBox from '@/components/floatBox.vue'
export default {
  components: {
    floatBox
  },
  computed: {
    navList() {
      return this.$store.state.common.nav_menu || [];
    },
    banner() {
      let obj = this.navList.find((item) => {
        return this.$route.fullPath == item.href
      });
      if(this.$route.fullPath == '/'){
        return
      }
      console.log("banner----------", obj)
      return obj?.topimages;
    },
  },
  data() {
    return {
      bgShow: false,
      messageShow: false,
      navS: [
        { name: 'トップページ', url: '/' },
        { name: '会社紹介', url: '/about' },
        { name: '商品紹介', url: '/product' },
        { name: 'ケーススタディー', url: '/case' },
        { name: 'パートナーズについて', url: '/partners' },
        { name: '最新ニュース', url: '/news' },
        { name: 'ご加盟', url: '/join' },
        // { name: '会員専用ページ', url: 'https://phromonjpn.com/' },
      ],
      formData: {
        name: '',
        mobile: '',
        city: '',
        content: ''
      },
      mobileShow: false
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    getShow(e) {
      this.bgShow = e
    },
    messageType(e) {
      this.messageShow = true
      this.bgShow = true
    },
    messageSubmit() {
      let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
      if (!this.formData.name || !this.formData.mobile || !this.formData.city || !this.formData.content) {
        this.$message.error('内容を完璧にしてください');
        return
      }
      this.$confirm('メッセージの提出を確認しますか?', 'ヒント', {
        confirmButtonText: '確定した',
        cancelButtonText: 'キャンセル',
        type: 'info'
      }).then(() => {
        this.$api.post('/home/submits/contactUs', this.formData).then(res => {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.messageShow = false
          this.bgShow = false
          this.$store.state.kfShow = false
        })
      })
    },
    hideBg() {
      this.bgShow = false
      this.messageShow = false
      this.$store.state.kfShow = false
    },
  }
}
</script>
<style>
.el-message-box__title span{
  font-size: 16px;
}
.el-message-box__message p{
  font-size: 14px;
}
.el-button--mini, .el-button--small span{
  font-size: 14px !important;
}
</style>

<style scoped="scoped" lang="scss">
.header_box {
  height: 100px;
}
.banner{
  // margin-bottom: 50px;
}
.banner img {
  width: 100%;
  display: block;
}
.header {
  height: 100px;
  border-bottom: 1px solid #1d2a55;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 99999;

  .messageBox {
    position: fixed;
    left: 50%;
    top: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
    z-index: 22;
    width: 60%;
    min-width: 400px;
    box-sizing: border-box;
    padding: 20px 40px;

    .cont {
      text-align: center;
    }

    h4 {
      color: #1d2a55;
      font-size: 30px;
      text-align: center;
    }

    .formInput {
      margin-top: 40px;

      input {
        background: rgba($color: #1d2a55, $alpha: 0.1);
        height: 48px;
        width: calc(100% / 3 - 20px);
        border: 0;
        margin-right: 30px;
        box-sizing: border-box;
        padding: 0 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    textarea {
      margin-top: 20px;
      background: rgba($color: #1d2a55, $alpha: 0.1);
      border: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
    }

    button {
      width: 50%;
      max-width: 300px;
      height: 48px;
      background: #1d2a55;
      color: #fff;
      font-size: 18px;
      margin: 30px auto 0;
      border-radius: 2px;
    }
  }

  .fixedBg {
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.4);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
  }

  .logo {
    width: 200px;
  }

  #nav {
    a {
      font-size: 20px;
      color: rgb(119, 119, 119);
      margin-left: 30px;
      display: inline-block;
      line-height: 64px;
      height: 64px;
      position: relative;

      &:hover,
      &.router-link-exact-active {
        color: #ff0000;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 6px;
          border-radius: 4px;
          position: absolute;
          left: 0;
          bottom: 0;
          background: #ff0000;
        }
      }
    }
  }
}

@media screen and(max-width:1400px) {
  .header {
    .logo {
      width: 170px;
    }

    #nav {
      a {
        margin-left: 20px;
        font-size: 18px;
      }
    }
  }
}

@media screen and(max-width:1200px) {
  .header {
    .logo {
      width: 110px;
    }

    #nav {
      a {
        margin-left: 15px;
        font-size: 16px;
      }
    }
  }
}

@media screen and(max-width:768px) {
.header_box {
  height: 60px;
}
  .block {
    width: 24px;
  }

  .header {
    height: 60px;

    .widthBox {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 100;
      background: #fff;
      height: 60px;
      box-sizing: border-box;
      padding: 0 20px;
      justify-content: space-between;
    }

    .logo {
      width: auto;
      height: 50px;
    }

    .icon {
      width: 24px;
    }

    #navM {
      background: #1d2a55;
      height: 100%;
      width: 60%;

      a {
        display: block;
        color: #fff;
        border-bottom: 1px solid #fff;
        font-size: 16px;
        line-height: 48px;
        padding: 0 10px;
      }
    }

    .navBg {
      position: fixed;
      left: 0;
      top: 60px;
      z-index: 100;
      background: rgba($color: #000000, $alpha: 0.4);
      width: 100%;
      height: calc(100% - 60px);

      #navM {
        width: 100%;

        a {
          margin: 0 16px;
          padding: 5px 0;

          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        img {
          widows: 20px;
          height: 20px;
        }

      }
    }
  }
}
</style>
