<template>
	<div class="titleOne" @click="toPage" :data-url="titleurl" :id="id">
		<div>
			<p>{{en}}</p>
			<div v-if="info" class="info">{{info}}</div>
			<h4>{{title}}</h4>
		</div>
		<span v-if="border" class="xiangA"></span>
		<span v-if="border" class="xiangB"></span>
	</div>
</template>

<script>
	export default {
	  components:{},
	  props:{
		  id:{
			  type:String
		  },
		  info:{
			  type:String
		  },
		  en:{
			  type:String,
		  },
		  title:{
			  type:String,
		  },
		  titleurl:{
		  			  type:String,
		  },
		  border:{
			  type:Boolean,
			  default:true
		  }
	  },
	  data(){
	    return {};
	  },
	  created(){},
	  methods:{
		  toPage(e){
			  var url = e.currentTarget.dataset.url
			  if(url){
				  this.$router.push({path:url, query:{id:this.id}});
			  }
		  }
	  },
	};
</script>

<style lang="scss" scoped="scoped">
	.titleOne{
		text-align:center;
		position: relative;
		height:130px;
		p{
			font-size:18px;
			line-height:18px;
			color:#1D2A55;
			text-transform:uppercase
		}
		.info{
			font-size:24px;
			line-height:24px;
			margin-top:10px;
			color:#1D2A55;
			opacity: 0.5;
		}
		h4{
			font-size:48px;
			color:#1D2A55;
			margin-top:15px;
			line-height:48px;
		}
		.xiangA{
			position: absolute;
			left:50%;
			bottom:0;
			transform: translate(-50%,0);
			width:218px;
			height:2px;
			background:rgba($color: #1D2A55, $alpha: 0.1);
		}
		.xiangB{
			position: absolute;
			left:50%;
			bottom:-2px;
			transform: translate(-50%,0);
			width:70px;
			height:6px;
			border-radius:3px;
			background:rgba($color: #1D2A55, $alpha: 1);
		}
	}
	@media screen and(max-width:1400px) {
		.titleOne{
			height:120px;
			p{
				font-size:16px;
			}
			h4{
				font-size:40px;
				margin-top:10px;
			}
		}
	}
	@media screen and(max-width:768px) {
		.titleOne{
			height:54px;
			p{
				font-size:12px;
				line-height:20px;
			}
			.info{
				font-size:14px;
				line-height:20px;
				margin-top:0px;
			}
			h4{
				font-size:18px;
				margin-top:0px;
				line-height:24px;
			}
			.xiangA{
				width:90px;
				height:1px;
			}
			.xiangB{
				width:24px;
				height:4px;
			}
		}
	}
</style>
