<template>
	<footer v-if="common" v-cloak>
		<div class="footerTop widthBox acea-row row-between">
			<div class="item hidden-xs-only" v-for="item in common.bottom_nav">
				<router-link class="title" :to="item.href || '/'">{{ item.name }}</router-link>
				<div class="xiang"></div>
				<div class="detail">
					<router-link class="name" :to="it.href || '/'" v-for="it in item.son">{{ it.name }}</router-link>
					<!-- <router-link class="name" :to="'/about?to=about_2'" v-for="it in item.son">{{it.name}}</router-link> -->
				</div>
			</div>
			<!-- <div class="item hidden-xs-only">
				<div class="title">关注我们</div>
				<div class="xiang"></div>
				<div class="detail" v-if="common.contact_us">
					<img class="code" v-for="item in common.contact_us.ewm" :src="item" alt="">
				</div>
			</div> -->

			<div class="footerNav hidden-sm-and-up">
				<div class="logo">
					<img src="@/assets/logo_04.png" alt="">
				</div>
        		<div @click="topBtn" style="border-top: 1px solid #666;">
					<router-link v-for="(item,index) in common.bottom_nav" :key="index" class="title" :to="item.href || '/'">{{ item.name}}</router-link>
                	<a target="_blank" href="https://phromonjpn.com/" class="title">会員専用ページ</a>
        		</div>
			</div>

			<!-- <div class="codeBox" v-if="common.contact_us">
				<img class="code" v-for="item in common.contact_us.ewm" :src="item" alt="">
			</div> -->
		</div>



		<div class="floatBox">
			<floatBox @messageShow="messageType" @getBgShow="getShow" :type="2" />
		</div>
		<div @click="hideBg" v-if="bgShow" class="fixedBg"></div>

		<div v-if="messageShow" class="messageBox">
			<h4>お問い合わせ</h4>
			<img src="@/assets/close.png" @click="hideBg" class="close" alt="">
			<div class="cont">
				<div class="formInput">
					<input v-model="formData.name" placeholder="お名前" type="text" />
					<input v-model="formData.mobile" placeholder="電話番号" type="text" />
					<input v-model="formData.city" placeholder="都道府県名" type="text" />
				</div>
				<textarea v-model="formData.content" rows="5" placeholder="お問い合わせ内容"></textarea>
				<button @click="messageSubmit">送信</button>
			</div>
		</div>


		<div class="copy" v-if="common.contact_us" v-cloak>
			<div class="widthBox">
				<span>{{ common.contact_us.copyright }}</span>
				<span class="hidden-xs-only" style="margin-left:50px;">{{ common.contact_us.icp }}</span>
				<span class="hidden-xs-only">会社所在地：{{ common.contact_us.address }}</span>
				<span class="hidden-xs-only" style="margin-left:150px;">電話番号 ：{{ common.contact_us.phone }}</span>
			</div>
		</div>
	</footer>
</template>

<script>
import floatBox from "@/components/floatBox.vue";
export default {
	components: {
		floatBox,
	},
	name: 'About',
	data() {
		return {
			messageShow: false,
			bgShow: false,

			formData: {
				name: "",
				mobile: "",
				city: "",
				content: "",
			},
		}
	},
	computed: {
		common() {
			return this.$store.state.common
		},
	},
		methods: {
		topBtn() {
			document.body.scrollIntoView();
		},
		messageSubmit() {
			let reg =
				/^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
			if (
				!this.formData.name ||
				!this.formData.mobile ||
				!this.formData.city ||
				!this.formData.content
			) {
				this.$message.error('内容を完璧にしてください');
				return;
			}
			this.$confirm('メッセージの提出を確認しますか?','ヒント', {
				confirmButtonText: '確定した',
				cancelButtonText:  'キャンセル',
				customClass: 'my_confirm',
				type: "info"
			}).then(() => {
				this.$api.post("/home/submits/contactUs", this.formData).then((res) => {
					this.$message({
						message: res.msg,
						type: "success",
					});
					this.messageShow = false;
					this.bgShow = false;
					this.$store.state.kfShow = false;
				});
			});
		},
		hideBg() {
			this.bgShow = false;
			this.messageShow = false;
			this.$store.state.kfShow = false;
		},
		messageType(e) {
			this.messageShow = true;
			this.bgShow = true;
		},
		getShow(e) {
			this.bgShow = e;
		},
	}
}
</script>

<style>

.my_confirm{
	width: auto !important;
}
</style>
<style lang="scss" scoped="scoped">
.messageBox {
	position: fixed;
	left: 50%;
	top: 50%;
	background: #fff;
	transform: translate(-50%, -50%);
	z-index: 22;
	width: 80%;
	min-width: 300px;
	box-sizing: border-box;
	padding: 20px 40px;
	.close{
		width: 24px;
		height: 24px;
		position: absolute;
		top: 14px;
		right: 14px;
	}
	.cont {
		text-align: center;
	}

	h4 {
		color: #1d2a55;
		font-size: 18px;
		text-align: center;
	}

	.formInput {
		margin-top: 20px;

		input {
			background: rgba($color: #1d2a55, $alpha: 0.1);
			height: 48px;
			width: 100%;
			margin-top: 10px;
			border: 0;
			margin-right: 30px;
			box-sizing: border-box;
			padding: 0 20px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	textarea {
		margin-top: 20px;
		background: rgba($color: #1d2a55, $alpha: 0.1);
		border: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 20px;
	}

	button {
		width: 50%;
		max-width: 300px;
		height: 48px;
		background: #1d2a55;
		color: #fff;
		font-size: 18px;
		margin: 30px auto 0;
		border-radius: 2px;
	}
}

.fixedBg {
	width: 100%;
	height: 100%;
	background: rgba($color: #000000, $alpha: 0.4);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20;
}

footer {
	margin-top: 80px;
	background: #1d2a55;
	color: #fff;

	.floatBox {
		display: none;
		z-index: 21;
	}

	.footerTop {
		padding: 80px 0 0 0;

		.item {
			width: auto;
			min-width: 50px;

			.title {
				font-size: 22px;
				color: #fff;
			}

			.xiang {
				background: rgba($color: #cccccc, $alpha: 0.2);
				height: 2px;
				position: relative;
				margin-top: 20px;

				&::after {
					content: "";
					display: block;
					width: 60px;
					height: 4px;
					background: #fff;
					position: absolute;
					left: 0;
					top: -1px;
					z-index: 1;
				}
			}

			.detail {
				padding: 30px 0;
				font-size: 18px;
				line-height: 48px;

				.name {
					color: #fff;
					display: block;
					line-height: 44px;
					font-size: 16px;
				}

				.code {
					width: 124px;
					margin: 0 10px 10px 0;
				}
			}
		}
	}

	.logo {
		img {
			width: 260px;
		}
	}

	.copy {
		border-top: 1px solid #fff;
		padding: 20px 0;
		font-size: 16px;
		line-height: 36px;
	}
}

@media screen and(max-width:1400px) {
	footer {
		margin-top: 70px;

		.footerTop {
			padding: 60px 0 0 0;

			.item {
				.title {
					font-size: 20px;
					color: #fff;
				}

				.xiang {
					margin-top: 20px;

					&::after {
						width: 50px;
						height: 4px;
					}
				}

				.detail {
					padding: 30px 0;
					font-size: 16px;
					line-height: 40px;

					.name {
						color: #fff;
						display: block;
						line-height: 40px;
						font-size: 16px;
					}

					.code {
						width: 110px;
					}
				}
			}
		}
	}
}

@media screen and(max-width:1200px) {
	footer {
		margin-top: 70px;

		.footerTop {
			padding: 60px 0 0 0;

			.item {
				.title {
					font-size: 18px;
				}

				.xiang {
					margin-top: 15px;

					&::after {
						width: 50px;
						height: 4px;
					}
				}

				.detail {
					padding: 20px 0;
					font-size: 14px;
					line-height: 36px;

					.name {
						color: #fff;
						line-height: 36px;
						font-size: 14px;
					}
				}
			}
		}
	}
}

@media screen and(max-width:768px) {
	.logo{
		img{
			width: 200px !important;
			height: auto;
			margin: 0 auto;
			display: block;
		}
		// border: 1px solid #666666;
		padding: 24px;
	}
	footer {
		margin-top: 40px;
		background: #1d2a55;

		.floatBox {
			display: block;
			margin: 36px 0;
		}

		.footerTop {
			padding: 0px;

			.footerNav {
				.title {
					width: 100%;
					font-size: 16px;
					text-align: left;
					color: #CCCCCC;
					display: inline-block;
					line-height: 44px;
					border-bottom: 1px solid #666666;
					padding: 10px 28px;
				}
			}

			.codeBox {
				.code {
					width: 80px;
					margin: 0 10px;
				}
			}
		}

		.copy {
			line-height: 0;
			border-color: #494949;
			padding: 20px 16px;
			span {
				display: block;
				line-height: 24px;
				margin-left: 0 !important;
				color: #bcbcbc;
			}
		}
	}
}
</style>
